<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <table class="table table-sm table-borderless">
            <tbody>
              <tr>
                <th>Hostname</th>
                <td>
                  {{ asup.hostname }}
                </td>
              </tr>
              <tr>
                <th>Version</th>
                <td>
                  {{ asup.version }}
                </td>
              </tr>
              <tr v-if="asup.serverOs">
                <th>Server OS</th>
                <td>
                  {{ asup.serverOs }}
                </td>
              </tr>
              <tr v-if="asup.capacity">
                <th>Capacity</th>
                <td>
                  {{ asup.capacity | prettyBytes }} ({{ asup.capacity | prettyBytes({ binary: true}) }})
                </td>
              </tr>
              <tr v-if="lastStatistic.capacityInfo && lastStatistic.capacityInfo.physicalSockets">
                <th>Physical Sockets</th>
                <td>
                  {{ lastStatistic.capacityInfo.physicalSockets }}
                </td>
              </tr>
              <tr v-if="asup.clientCount">
                <th>Clients</th>
                <td>
                  {{ asup.clientCount }}
                </td>
              </tr>

              <tr>
                <th>System Timezone</th>
                <td colspan="2">
                  {{ asup.timezone }}
                </td>
              </tr>
              <tr>
                <th>Last autosupport data</th>
                <td colspan="2">
                  {{ asup.updateTimestampUtc | formatDateTime }}
                </td>
              </tr>
              <tr v-if="asup.updateTimestampUtc > asup.lastBuildModelTimestamp">
                <th>Model built on (UTC)</th>
                <td colspan="2">
                  {{ asup.lastBuildModelTimestamp | formatDateTime }}<br>
                  <small class="text-hint text-danger">The displayed data is older than the latest Autosupport information. The displayed data should be updated a few minutes after the latest Autosupport data.</small>
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>

        <b-card title="Statistic last 1 day">
          <b-row>
            <b-col>
              <table class="table table-sm table-borderless">
                <tbody>
                  <tr>
                    <th>Succeeded Clients</th>
                    <td>
                      {{ asup.calculatedStats.succeededClientsLast1DayCount }}
                    </td>
                  </tr>
                  <tr>
                    <th>Active Clients</th>
                    <td>
                      {{ asup.calculatedStats.activeClientsLast1DayCount }}
                    </td>
                  </tr>
                  <tr>
                    <th>Warning Clients</th>
                    <td>
                      {{ asup.calculatedStats.warningClientsLast1DayCount }}
                    </td>
                  </tr>
                  <tr>
                    <th>Failed Clients</th>
                    <td>
                      {{ asup.calculatedStats.failedClientsLast1DayCount }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col>
              <apexchart
                type="bar"
                height="150"
                :options="clientStatus1DayChartOptions"
                :series="clientStatus1DayChartSeries"
              />
            </b-col>
          </b-row>

          <hr>

          <b-row>
            <b-col>

              <table class="table table-sm table-borderless">
                <tbody>
                  <tr>
                    <th>Succeeded Clone Jobs</th>
                    <td>
                      {{ asup.calculatedStats.succeededCloneJobsLast1DayCount }}
                    </td>
                  </tr>
                  <tr>
                    <th>Active Clone Jobs</th>
                    <td>
                      {{ asup.calculatedStats.activeCloneJobsLast1DayCount }}
                    </td>
                  </tr>
                  <tr>
                    <th>Warning Clone Jobs</th>
                    <td>
                      {{ asup.calculatedStats.warningCloneJobsLast1DayCount }}
                    </td>
                  </tr>
                  <tr>
                    <th>Failed Clone Jobs</th>
                    <td>
                      {{ asup.calculatedStats.failedCloneJobsLast1DayCount }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col>
              <apexchart
                type="bar"
                height="150"
                :options="cloneJobStatus1DayChartOptions"
                :series="cloneJobStatus1DayChartSeries"
              />
            </b-col>
          </b-row>
        </b-card>

        <b-card title="Statistic last 3 day">
          <b-row>
            <b-col>
              <table class="table table-sm table-borderless">
                <tbody>
                  <tr>
                    <th>Succeeded Clients</th>
                    <td>
                      {{ asup.calculatedStats.succeededClientsLast3DayCount }}
                    </td>
                  </tr>
                  <tr>
                    <th>Active Clients</th>
                    <td>
                      {{ asup.calculatedStats.activeClientsLast3DayCount }}
                    </td>
                  </tr>
                  <tr>
                    <th>Warning Clients</th>
                    <td>
                      {{ asup.calculatedStats.warningClientsLast3DayCount }}
                    </td>
                  </tr>
                  <tr>
                    <th>Failed Clients</th>
                    <td>
                      {{ asup.calculatedStats.failedClientsLast3DayCount }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col>
              <apexchart
                type="bar"
                height="150"
                :options="clientStatus3DayChartOptions"
                :series="clientStatus3DayChartSeries"
              />
            </b-col>
          </b-row>

          <hr>

          <b-row>
            <b-col>

              <table class="table table-sm table-borderless">
                <tbody>
                  <tr>
                    <th>Succeeded Clone Jobs</th>
                    <td>
                      {{ asup.calculatedStats.succeededCloneJobsLast3DayCount }}
                    </td>
                  </tr>
                  <tr>
                    <th>Active Clone Jobs</th>
                    <td>
                      {{ asup.calculatedStats.activeCloneJobsLast3DayCount }}
                    </td>
                  </tr>
                  <tr>
                    <th>Warning Clone Jobs</th>
                    <td>
                      {{ asup.calculatedStats.warningCloneJobsLast3DayCount }}
                    </td>
                  </tr>
                  <tr>
                    <th>Failed Clone Jobs</th>
                    <td>
                      {{ asup.calculatedStats.failedCloneJobsLast3DayCount }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col>
              <apexchart
                type="bar"
                height="150"
                :options="cloneJobStatus3DayChartOptions"
                :series="cloneJobStatus3DayChartSeries"
              />
            </b-col>
          </b-row>
        </b-card>

        <b-card title="Statistic last 7 day">
          <b-row>
            <b-col>
              <table class="table table-sm table-borderless">
                <tbody>
                  <tr>
                    <th>Succeeded Clients</th>
                    <td>
                      {{ asup.calculatedStats.succeededClientsLast7DayCount }}
                    </td>
                  </tr>
                  <tr>
                    <th>Active Clients</th>
                    <td>
                      {{ asup.calculatedStats.activeClientsLast7DayCount }}
                    </td>
                  </tr>
                  <tr>
                    <th>Warning Clients</th>
                    <td>
                      {{ asup.calculatedStats.warningClientsLast7DayCount }}
                    </td>
                  </tr>
                  <tr>
                    <th>Failed Clients</th>
                    <td>
                      {{ asup.calculatedStats.failedClientsLast7DayCount }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col>
              <apexchart
                type="bar"
                height="150"
                :options="clientStatus7DayChartOptions"
                :series="clientStatus7DayChartSeries"
              />
            </b-col>
          </b-row>
          <hr>

          <b-row>
            <b-col>

              <table class="table table-sm table-borderless">
                <tbody>
                  <tr>
                    <th>Succeeded Clone Jobs</th>
                    <td>
                      {{ asup.calculatedStats.succeededCloneJobsLast7DayCount }}
                    </td>
                  </tr>
                  <tr>
                    <th>Active Clone Jobs</th>
                    <td>
                      {{ asup.calculatedStats.activeCloneJobsLast7DayCount }}
                    </td>
                  </tr>
                  <tr>
                    <th>Warning Clone Jobs</th>
                    <td>
                      {{ asup.calculatedStats.warningCloneJobsLast7DayCount }}
                    </td>
                  </tr>
                  <tr>
                    <th>Failed Clone Jobs</th>
                    <td>
                      {{ asup.calculatedStats.failedCloneJobsLast7DayCount }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col>
              <apexchart
                type="bar"
                height="150"
                :options="cloneJobStatus7DayChartOptions"
                :series="cloneJobStatus7DayChartSeries"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <template #header>
            <h4 class="card-title">
              Monitor Status <small class="text-muted">({{ monitors.length }} monitors checked)</small>
            </h4>
            <div
              v-if="isMonitorOverwritten"
              class="float-right"
            >
              <b-badge
                v-b-popover.hover="'At least one monitor has been overwritten for this system.'"
                pill
                variant="info"
              >
                <small>Monitor overwritten <feather-icon icon="HelpCircleIcon" /></small>
              </b-badge>
            </div>
          </template>

          <ul
            v-if="activeMonitors.length > 0"
            style="list-style: none"
          >
            <li
              v-for="monitor in activeMonitors"
              :id="`monitor_${monitor.monitorId}`"
              :key="monitor.monitorId"
            >
              <monitor-status-icon
                :status="monitor.status"
                :text="monitor.shortMessage || monitor.exceptionMessage"
              />

              <b-popover
                custom-class="wide-popover"
                :target="`monitor_${monitor.monitorId}`"
                triggers="hover"
                :variant="popoverVariant(monitor.status)"
              >
                <template #title>
                  Detailed message
                </template>

                <span
                  v-if="monitor.message"
                  v-html="monitor.message"
                />
                <span v-else>No detailed message</span>
              </b-popover>
            </li>
          </ul>
          <div v-else>
            <monitor-status-icon
              :status="0"
              text="All monitors are OK"
            />
          </div>
        </b-card>

        <b-card :title="`Active Alerts (${sortedAlerts.length})`">
          <div class="table-responsive config-table-wrapper">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th style="min-width: 180px">
                    Timestamp
                  </th>
                  <th>Priority</th>
                  <th>Category</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(alert, index) in sortedAlerts"
                  :key="index"
                >
                  <td>{{ alert.dateTime | formatDateTime }}</td>
                  <td>
                    <b-badge :variant="getBadgeVariantByAlertPriority(alert.priority)">
                      {{ alert.priority }}
                    </b-badge>
                  </td>
                  <td>{{ alert.category }}</td>
                  <td>{{ alert.message }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { $themeColors } from '@themeConfig'
import {
  BRow, BCol, BCard, VBPopover, BPopover, BBadge,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import VueApexCharts from 'vue-apexcharts'
import MonitorStatusIcon from '@/components/monitor/MonitorStatusIcon.vue'

import AsupStatusService from '@/service/asupStatus.service'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    MonitorStatusIcon,
    apexchart: VueApexCharts,
    BPopover,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      monitors: [],
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      isHost: 'auth/isHost',
    }),
    isMonitorOverwritten() {
      if (this.isHost && this.asup.isHostMonitorOverwritten) {
        return true
      }
      if (!this.isHost && this.asup.isTenantMonitorOverwritten) {
        return true
      }

      return false
    },
    clientStatus1DayChartOptions() {
      return {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
          offsetY: -25,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            distributed: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: ['Succeeded', 'Active', 'Warning', 'Failed'],
        },
        colors: [$themeColors.success, $themeColors.info, $themeColors.warning, $themeColors.danger],
      }
    },
    cloneJobStatus1DayChartOptions() {
      return {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
          offsetY: -25,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            distributed: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: ['Succeeded', 'Active', 'Warning', 'Failed'],
        },
        colors: [$themeColors.success, $themeColors.info, $themeColors.warning, $themeColors.danger],
      }
    },
    cloneJobStatus3DayChartOptions() {
      return {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
          offsetY: -25,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            distributed: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: ['Succeeded', 'Active', 'Warning', 'Failed'],
        },
        colors: [$themeColors.success, $themeColors.info, $themeColors.warning, $themeColors.danger],
      }
    },
    cloneJobStatus7DayChartOptions() {
      return {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
          offsetY: -25,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            distributed: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: ['Succeeded', 'Active', 'Warning', 'Failed'],
        },
        colors: [$themeColors.success, $themeColors.info, $themeColors.warning, $themeColors.danger],
      }
    },
    clientStatus3DayChartOptions() {
      return {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
          offsetY: -25,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            distributed: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: ['Succeeded', 'Active', 'Warning', 'Failed'],
        },
        colors: [$themeColors.success, $themeColors.info, $themeColors.warning, $themeColors.danger],
      }
    },
    clientStatus7DayChartOptions() {
      return {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
          offsetY: -25,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            distributed: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: ['Succeeded', 'Active', 'Warning', 'Failed'],
        },
        colors: [$themeColors.success, $themeColors.info, $themeColors.warning, $themeColors.danger],
      }
    },
    clientStatus1DayChartSeries() {
      if (!this.asup || !this.asup.calculatedStats) {
        return [{ data: [0, 0, 0, 0] }]
      }
      return [{
        data: [
          this.asup.calculatedStats.succeededClientsLast1DayCount,
          this.asup.calculatedStats.activeClientsLast1DayCount,
          this.asup.calculatedStats.warningClientsLast1DayCount,
          this.asup.calculatedStats.failedClientsLast1DayCount,
        ],
      },
      ]
    },
    cloneJobStatus1DayChartSeries() {
      if (!this.asup || !this.asup.calculatedStats) {
        return [{ data: [0, 0, 0, 0] }]
      }
      return [{
        data: [
          this.asup.calculatedStats.succeededCloneJobsLast1DayCount,
          this.asup.calculatedStats.activeCloneJobsLast1DayCount,
          this.asup.calculatedStats.warningCloneJobsLast1DayCount,
          this.asup.calculatedStats.failedCloneJobsLast1DayCount,
        ],
      },
      ]
    },
    clientStatus3DayChartSeries() {
      if (!this.asup || !this.asup.calculatedStats) {
        return [{ data: [0, 0, 0, 0] }]
      }
      return [{
        data: [
          this.asup.calculatedStats.succeededClientsLast3DayCount,
          this.asup.calculatedStats.activeClientsLast3DayCount,
          this.asup.calculatedStats.warningClientsLast3DayCount,
          this.asup.calculatedStats.failedClientsLast3DayCount,
        ],
      },
      ]
    },
    cloneJobStatus3DayChartSeries() {
      if (!this.asup || !this.asup.calculatedStats) {
        return [{ data: [0, 0, 0, 0] }]
      }
      return [{
        data: [
          this.asup.calculatedStats.succeededCloneJobsLast3DayCount,
          this.asup.calculatedStats.activeCloneJobsLast3DayCount,
          this.asup.calculatedStats.warningCloneJobsLast3DayCount,
          this.asup.calculatedStats.failedCloneJobsLast3DayCount,
        ],
      },
      ]
    },
    clientStatus7DayChartSeries() {
      if (!this.asup || !this.asup.calculatedStats) {
        return [{ data: [0, 0, 0, 0] }]
      }
      return [{
        data: [
          this.asup.calculatedStats.succeededClientsLast7DayCount,
          this.asup.calculatedStats.activeClientsLast7DayCount,
          this.asup.calculatedStats.warningClientsLast7DayCount,
          this.asup.calculatedStats.failedClientsLast7DayCount,
        ],
      },
      ]
    },
    cloneJobStatus7DayChartSeries() {
      if (!this.asup || !this.asup.calculatedStats) {
        return [{ data: [0, 0, 0, 0] }]
      }
      return [{
        data: [
          this.asup.calculatedStats.succeededCloneJobsLast7DayCount,
          this.asup.calculatedStats.activeCloneJobsLast7DayCount,
          this.asup.calculatedStats.warningCloneJobsLast7DayCount,
          this.asup.calculatedStats.failedCloneJobsLast7DayCount,
        ],
      },
      ]
    },
    activeMonitors() {
      return this.monitors.filter(x => x.status !== 0)
    },
    sortedAlerts() {
      const { alerts } = this.asup.details
      if (!alerts) {
        return []
      }
      return alerts
        .concat().sort((a, b) => (a.dateTime > b.dateTime ? -1 : 1))
    },
    lastStatistic() {
      return this.asup.details.lastStatistic || {}
    },
  },
  created() {
    AsupStatusService.getActiveListAsync({ asupSystemId: this.asup.id })
      .then(result => {
        this.monitors = result.items
      })
      .finally(() => {
        this.isLoading = false
      })
  },
  methods: {
    popoverVariant(status) {
      switch (status) {
        case 0: return 'success'
        case 10: return 'info'
        case 20: return 'warning'
        case 30:
          return 'danger'
        default: return 'danger'
      }
    },
    getBadgeVariantByAlertPriority(alertPriority) {
      switch (alertPriority.toLowerCase()) {
        case 'emergency':
        case 'critical':
        case 'alert':
          return 'danger'
        case 'warning':
          return 'warning'
        case 'waiting':
        case 'info':
          return 'info'
        default:
          return 'secondary'
      }
    },
  },
}
</script>

<style scoped>
  .config-table-wrapper {
    max-height: 400px;
    overflow-y: auto
  }
</style>
